import { Button, Typography } from '@mui/material';
import { classNames } from '@infotrack/infotrackgo.web.core/framework';
import React from 'react';
import { uuid } from '@infotrack/infotrackgo.web.core/framework/utils/uuid';

import styles from '../styles/homeinfopane.module.scss';

interface Props {
  title: string | string[];
  descriptionLine1?: string;
  descriptionLine2?: string;
  actionButtonText?: string;
  actionButtonOnClick?: () => void;
  // A classname to add extra styling to the pane.
  className?: string;
  // The imported image to use as the HomeInfoPane background!
  backgroundImage?: any;
}

const getTitle = (title: string | string[]) => {
    if (title instanceof Array){
      return <Typography variant="h1" className={styles.itgHomePaneInfoHeadingTitle}>{getMultineTitle(title)}</Typography>;
    }
    return <Typography  variant="h1" className={styles.itgHomePaneInfoHeadingTitle}>{title}</Typography>
  }

const getMultineTitle = (title: string[]): React.ReactNode => {
  return title.map( text => {
    return (
      <React.Fragment key={uuid()}>
        <span>{text}</span>
        <br className={styles.itgHomePaneHeaderLinebreakTitle}/>
      </React.Fragment>
    );
  });
};

export const HomeInfoPane = (props: Props) => {
    const paneClassNames = classNames(styles.itgHomePaneInfo, props.className);
    return (
        <div
          className={paneClassNames}
          style={{ backgroundImage: `url(${props.backgroundImage?.src})` }}
        >
          <div className={styles.itgHomePaneInfoHeading}>{getTitle(props.title)}</div>
          {
            props.descriptionLine1 ?
            <div className={styles.itgHomePaneInfoDescription}>{props.descriptionLine1}</div> :
            null
          }
          {
            props.descriptionLine2 ?
            <div className={styles.itgHomePaneInfoDescription}>{props.descriptionLine2}</div> :
            null
          }
          {
            props.actionButtonText
              ? (
                  <div className={styles.itgHomePaneButtonContainer}>
                    <Button
                      onClick={props.actionButtonOnClick}
                    >
                      {props.actionButtonText}
                    </Button>
                  </div>
                )
              : null
          }
        </div>
    );
};